$color-mode-type: data;

@import '~bootstrap/scss/bootstrap';

.main {
    min-width: 400px;
    min-height: 240px;
    height: 100vh !important;
    background: url("assets/bgimage.png") no-repeat center center;
    background-size: cover;
}
